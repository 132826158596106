import * as React from "react"
import Button from "../Button/Button"
import { ContactStyles } from "./ContactStyles"
import { StaticImage } from "gatsby-plugin-image"

const Contact = () => {
  return (
    <ContactStyles className="section">
      <div className="contact__content">
        <div className="contact__image-wrapper">
          <StaticImage
            className="contact__image"
            imgClassName="banner__image--content"
            src="../../../static/sophie.webp"
            alt="Sophie Orsbourn"
            layout="fullWidth"
            placeholder="blurred"
          />
        </div>
        <div className="contact__details">
          <h4>
            {"Sophie Orsbourn"}
            <br />
            <small>{"Dip. Int. Des."}</small>
          </h4>
        </div>
      </div>

      <p>
        <strong>Hours:</strong>
        <br />
        Tuesday, Wednesday, Thursday: 9.30am - 2.30pm. <br />
        Otherwise, by appointment.
      </p>

      <form
        name="contact"
        method="POST"
        data-netlify="true"
        action="/success"
      >
        <input type="hidden" name="form-name" value="contact" />
        <input placeholder="Your name..." type="text" name="name" />
        <input placeholder="Your email..." type="email" name="email" />
        <textarea
          placeholder="Your message..."
          name="message"
          rows="5"
        ></textarea>
        <Button type="submit" text="Send Message" />
      </form>
    </ContactStyles>
  )
}

export default Contact
